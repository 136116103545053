import _ from 'lodash'
import { bindObjectFunctions } from '../../utils/utils'
import translations from '../../utils/translations'
import Experiments from '@wix/wix-experiments'
import { createCollectionsApi as createWixCodeCollectionsApi } from '@wix/wix-code-collections-api'
import createEditorTransportLayer from '@wix/wix-code-collections-transport-editor'
import { initBiLoggerForEditorApp } from '../../utils/bi'
import RemoteApi from '../../panels/commons/remote-api'
import CoreApi from '../core/core-api'
import { editorAppMetaData } from './editor-app'
import { fetchAllThemes } from '../core/preset/themes-service'
import ExperimentsService from '../../utils/experiments-service'
import { PanelName } from '@wix/forms-common'
import { EVENTS } from '../../constants/bi'
import { EditorPlatformApp } from '@wix/platform-editor-sdk'
import { updateMetaSiteId, initMonitoring, handleError, captureBreadcrumb } from './monitoring'

// start app functions
const startApp = async (origin: Origin) => {
  const api = await editorAppMetaData.getCoreApi()
  const originType = _.get(origin, 'info.type')

  if (originType === 'APP_MARKET') {
    api.managePanels.openModalPanel(null, PanelName.ADD_FORM, () =>
      api.log({ evid: EVENTS.PANELS[PanelName.ADD_FORM].OPEN_PANEL }),
    )
    return
  }

  const preset = _.get(origin, 'info.preset')

  if (!preset) {
    return
  }

  const containerRef = origin.info.containerRef

  if (originType === 'ADD_PANEL') {
    if (await api.addForm.preventFormAdditionAddPanel(preset, containerRef)) {
      return
    }

    return api.addForm.addForm(preset, {
      containerRef: origin.info.containerRef,
      source: originType,
      shouldSelectForm: _.get(origin, 'info.select_form', false),
    })
  }

  await api.addForm.addForm(preset, {
    containerRef,
    source: originType,
    createCollection: false,
    targetPageRef: _.get(origin, 'info.targetPageRef'),
    shouldSelectForm: _.get(origin, 'info.select_form'),
    shouldSaveHistory: _.get(origin, 'info.save_history', true),
  })

  // for custom signup or other external flows do not save the site, the owner of the flow will do it if needed
  if (originType === 'PAGE_SETTINGS_PANEL') {
    return Promise.resolve()
  }

  return api.saveSite()
}

// init functions
const initExperiments = async () => {
  const experiments: Experiments = new Experiments()
  await experiments.load('wix-form-builder')

  ExperimentsService.init(experiments)
  return experiments
}

const initCollectionsApi = ({
  editorSDK,
  appDefinitionId,
}: {
  editorSDK: EditorSDK
  appDefinitionId: string
}) => {
  const transportLayer = createEditorTransportLayer(editorSDK, appDefinitionId)
  return createWixCodeCollectionsApi({ transportLayer, ownerAppId: appDefinitionId })
}

const initTranslations = async ({
  boundEditorSDK,
  experiments,
}: {
  boundEditorSDK: BoundEditorSDK
  experiments: Experiments
}) => {
  const locale = await boundEditorSDK.environment.getLocale()
  return translations.init(locale, experiments.all())
}

const registerToCustomEvents = ({
  boundEditorSDK,
  experiments,
}: {
  boundEditorSDK: BoundEditorSDK
  experiments: Experiments
}) => {
  const eventTypes = _.compact([
    'pageDuplicated',
    experiments.enabled('specs.crm.FormsEditorRulesTab') && 'componentDataChanged',
  ])

  return boundEditorSDK.document.application.registerToCustomEvents({
    eventTypes,
  })
}

const initApp = async ({
  appDefinitionId,
  editorSDK,
  origin,
  ravenInstance,
  fedopsLogger,
}: {
  appDefinitionId: string
  editorSDK: EditorSDK
  origin: Origin
  ravenInstance
  fedopsLogger
}) => {
  captureBreadcrumb({
    message: 'start initializing app api',
    category: 'init-app',
    level: 'info',
    data: {
      origin,
    },
  })

  const [msid, siteId, uuid, experiments] = await Promise.all([
    editorSDK.info.getMetaSiteId(appDefinitionId),
    editorSDK.info.getSiteId(appDefinitionId),
    editorSDK.info.getUserId(appDefinitionId),
    initExperiments(),
  ])

  updateMetaSiteId(msid)

  captureBreadcrumb({
    message: 'prepare api data',
    category: 'init-app',
    level: 'info',
    data: {
      msid,
      siteId,
    },
  })

  const boundEditorSDK: BoundEditorSDK = bindObjectFunctions(editorSDK, appDefinitionId)
  const collectionsApi = initCollectionsApi({ editorSDK, appDefinitionId })
  const remoteApi = new RemoteApi({ boundEditorSDK, experiments, ravenInstance })
  const biLogger = initBiLoggerForEditorApp(msid, uuid, origin.type)
  await initTranslations({ boundEditorSDK, experiments })

  captureBreadcrumb({
    message: 'create core api instance',
    category: 'init-app',
    level: 'info',
  })

  const coreApi = new CoreApi(boundEditorSDK, editorSDK, {
    apis: {
      collectionsApi,
      remoteApi,
    },
    experiments,
    ravenInstance,
    biLogger,
    origin,
    appDefinitionId,
    fedopsLogger,
    siteId,
  })

  captureBreadcrumb({
    message: 'set app api',
    category: 'init-app',
    level: 'info',
  })

  await boundEditorSDK.editor.setAppAPI(coreApi.generateRuntimeApi())

  coreApi.appState.setState()
  coreApi.steps.updateMultiStepFormsTitles()

  editorAppMetaData.setCoreApi(coreApi)
  fetchAllThemes(ravenInstance)

  await registerToCustomEvents({ boundEditorSDK, experiments })

  captureBreadcrumb({
    message: 'log app loaded',
    category: 'init-app',
    level: 'info',
  })

  fedopsLogger.appLoaded()

  if (experiments.enabled('specs.crm.FormsEditorDevMode')) {
    const globalAny = global as any

    globalAny.boundEditorSDK = boundEditorSDK

    // globalAny.convertSelectedForm = async () => {
    //   console.log('start converting selected contact form')

    //   const selectedContactForm = (await boundEditorSDK.selection.getSelectedComponents())[0]

    //   console.log('selectedContactForm', selectedContactForm)

    //   await coreApi.addForm.addContactForm(selectedContactForm, true) // the "copy paste" we are doing is removing crucial data (mobileStructure for example)
    // }
  }

  captureBreadcrumb({
    message: 'initialized finished',
    category: 'init-app',
    level: 'info',
  })

  if (coreApi.isExperimentEnabled('specs.crm.FormsEditorMigrateContactFields')) {
    coreApi.contactSync.applyContactMigration()
  }

  editorAppMetaData.isInitialized = true
}

// exported function
export const editorReady: EditorPlatformApp['editorReady'] = async (
  editorSDK,
  appDefinitionId,
  payload,
) => {
  try {
    const { origin } = payload

    if (!editorAppMetaData.isInitialized) {
      const { monitoring } = payload
      const { ravenInstance, fedopsLogger } = initMonitoring(monitoring)

      await initApp({
        appDefinitionId,
        editorSDK,
        origin: <Origin>origin,
        ravenInstance,
        fedopsLogger,
      })
    }

    return startApp(<Origin>origin)
  } catch (error) {
    handleError(error, {
      extra: { message: 'Editor ready failed to load' },
    })
  }
}
