import _ from 'lodash'
import { CRM_TYPES } from '../../../../../constants/crm-types-tags'

export const getFieldById = (fields: Array<FormField>, fieldCompId: string): FormField =>
  _.find(fields, (field: FormField) => field.componentRef.id === fieldCompId)

export const getFieldWithNonPrimaryRole = (fields: Array<FormField>, role: string): FormField =>
  _.find(fields, (field: FormField) => {
    const connection = _.find(
      field.nonPrimaryConnections,
      (connection: ComponentConnection) => connection.role === role,
    )

    return !!connection
  })

export const getEmailFields = (fields: Array<FormField>): Array<FormField> =>
  _.filter(fields, (field: FormField) => field.crmType === CRM_TYPES.EMAIL)
